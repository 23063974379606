@use "../../styles/colors.scss" as *;

.settings {
    &__col_1 {
        width: 320px;
        background-color: $whiteBG;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 29px;
        border-radius: 12px;
        min-height: 80vh;

        h1 {
            color: $darkBlueText;
            font-size: 24px;
            font-weight: 600;
            line-height: 125%;
            letter-spacing: 0.5px;
        }

        &--dark {
            background-color: $darkBG;

            h1 {
                color: $whiteText;
            }
        }
    }

    &__nav {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;

        img {
            width: 20px;
            height: 20px;
        }

        button {
            display: flex;
            padding: 12px;
            gap: 10px;
            align-items: center;
            color: $darkBlueText;
            font-size: 16px;
            font-weight: 550;
            line-height: 24px;
            background-color: $whiteBG;
            border: none;
            border-radius: 8px;
            cursor: pointer;

            &.active {
                background-color: $lightBlueBG;
            }
        }

        &--dark {
            button {
                background-color: $darkBG;
                color: $whiteText;
            }

            button.active {
                background-color: $darkestBG;
            }
        }
    }

    &__col_2 {
        flex: 1;
        padding: 24px;
        background-color: $whiteBG;
        border-radius: 12px;
        display: flex;
        flex-direction: column;
        gap: 29px;

        h2 {
            color: $darkBlueText;
            font-size: 24px;
            font-weight: 600;
            line-height: 125%;
            letter-spacing: 0.5px;
        }

        &--dark {
            background-color: $darkBG;

            h2 {
                color: $whiteText;
            }
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        position: relative;
        gap: 29px;
    }

    &__inputs {
        display: flex;
        flex-direction: column;
        gap: 18px;
        max-width: 520px;
    }

    &__group {
        display: flex;
        flex-direction: column;
        gap: 6px;

        label {
            color: $darkBlueText;
            font-size: 14px;
            font-weight: 600;
            line-height: 150%;
        }

        &--dark label {
            color: $whiteText;
        }
    }

    &__img_container {
        display: flex;
        align-items: center;
        gap: 17px;
        cursor: pointer;
        position: relative;

        img {
            width: 64px;
            height: 64px;
            border-radius: 50%;
            object-fit: cover;
        }

        p:first-of-type {
            color: $darkBlueText;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
        }

        p:last-of-type {
            color: $neutralLightText;
            font-size: 14px;
            font-weight: 500;
            line-height: 150%;
        }

        &--dark {
            p:first-of-type {
                color: $whiteText;
            }
        }
    }

    &__clear_picture {
        position: absolute;
        width: 24px;
        height: 24px;
        top: 0;
        left: 50px;
        z-index: 10;
    }

    &__link {
        display: flex;
        justify-content: space-between;
        color: $darkBlueText;
        font-size: 16px;
        font-weight: 550;
        line-height: 24px;
        max-width: 300px;

        &--dark {
            color: $whiteText;
        }

        img {
            width: 20px;
            height: 20px;
        }
    }
}
