@use "../../../styles/colors" as *;

.select {
    cursor: pointer;
    position: relative;

    &__header {
        display: flex;
        border-radius: 68px;
        padding: 12px 16px;
        justify-content: space-between;
        align-items: center;
        background: $lightBlueBG;

        &--active {
            border-radius: 24px 24px 0 0;
        }

        span {
            color: $darkBlueText;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }
    }

    &__body {
        position: absolute;
        display: flex;
        flex-direction: column;
        z-index: 10;
        max-height: 200px;
        overflow: auto;
        border-radius: 0 0 24px 24px;
        width: 100%;

        div {
            padding: 12px 16px;
            font-weight: 500;
            font-size: 14px;
            border-top: 1px solid darken($lightBlueBG, 2%);
            background: $lightBlueBG;

            &:hover {
                background: darken($lightBlueBG, 2%);
            }

            &:last-of-type {
                border-radius: 0 0 24px 24px;
            }
        }
    }

    &--dark {
        .select__header {
            background: $darkestBG;

            span {
                color: $whiteText;
            }
        }

        .select__body div {
            border-top: 1px solid lighten($darkestBG, 2%);
            background: $darkestBG;
            color: $neutralLightText;

            &:hover {
                background: lighten($darkestBG, 2%);
            }
        }
    }
}
