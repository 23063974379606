@use "../../styles/colors.scss" as *;

.creator {
    max-width: 800px;
    margin: 0 auto;
    padding: 32px;

    &__header {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 64px;

        img {
            width: 150px;
            height: 150px;
            border-radius: 50%;
            margin-bottom: 24px;
            background-color: $darkBlueBG;
        }

        h1 {
            font-size: 26px;
            color: $darkBlueText;
        }

        p {
            font-size: 14px;
            color: $neutralLightText;
            margin-bottom: 24px;
        }

        button {
            display: flex;
            padding: 4px 12px;
            align-items: center;
            gap: 8px;
            border-radius: 36px;
            background: $darkBlueBG;
            border: none;
            color: $whiteText;
            font-size: 14px;
            font-weight: 550;
            line-height: 24px;

            &.followed {
                background: transparent;
                border: 1px solid $darkBlueBorder;
                color: $darkBlueText;

                &::before {
                    display: none;
                }
            }
        }
    }

    &__posts {
        display: flex;
        flex-direction: column;
        gap: 16px;

        .post {
            background-color: $whiteBG;
            padding: 24px;
            border-radius: 8px;
        }
    }

    &--dark {
        .creator__header {
            img {
                background-color: $darkBG;
            }

            h1 {
                color: $whiteText;
            }

            p {
                color: $neutralText;
            }

            button {
                background: $darkBG;
                color: $whiteText;

                &.followed {
                    background: transparent;
                    border: 1px solid $whiteBorder;
                    color: $whiteText;

                    &::before {
                        display: none;
                    }
                }
            }
        }

        .creator__posts .post {
            background-color: $darkBG;
        }
    }
}
