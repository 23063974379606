@use "../../styles/colors.scss" as *;

.portfolio_dashboard {
    margin-top: -24px;
}
.portfolio {
    &__col_1 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex: 1;
        margin-bottom: 60px;
    }

    &__tab {
        display: flex;
        flex: 1;
        margin-left: 6px;

        button {
            width: 100px;
            padding-top: 8px;
            padding-bottom: 8px;
            background: transparent;
            border: none;
            border-top-left-radius: 14px;
            border-top-right-radius: 14px;
            color: $darkText;
            font-size: 20px;
            font-weight: 600;            
        }
        .active {
            background: #ffffff;
        }
    }
    &__split {
        border: 1px solid $darkText;
    }
    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
        align-items: center;

        .portfolio__name p {
            color: $darkBlueText;
            font-size: 40px;
            font-weight: 600;
            line-height: 125%;
            letter-spacing: 0.5px;
        }

        &--dark .portfolio__name p {
            color: $whiteText;
        }

        .portfolio__name span {
            font-size: 20px;
            display: flex;
            align-items: center;
            gap: 4px;
            font-weight: 550;

            &::before {
                content: "";
                width: 20px;
                height: 20px;
                background-size: cover;
            }

            &.positive {
                color: $greenText;

                &::before {
                    background-image: url("../../assets/arrow-top-right.svg");
                }
            }

            &.negative {
                color: $redText;

                &::before {
                    background-image: url("../../assets/arrow-bottom-right.svg");
                }
            }
        }
    }

    &__name {
        display: flex;
        gap: 14px;
        align-items: center;
    }

    &__wrapper {
        display: flex;
        gap: 24px;

        & > div {
            flex: 1;

            &:first-of-type {
                flex: 0.5;
            }
        }
    }

    &__img {
        width: 100%;
        height: auto;
    }

    &__allocation {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }

    &__allocation--dark {
        .portfolio__allocation_name {
            p {
                color: $whiteText;
            }

            p:last-of-type {
                color: $neutralLightText;
            }
        }

        .portfolio__allocation_percentage span {
            color: $whiteText;
        }
    }

    &__allocation_item {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__allocation_name {
        display: flex;
        gap: 18px;
        align-items: center;

        img {
            width: 32px;
            height: 32px;
        }

        p {
            color: $darkBlueText;
            font-size: 18px;
            font-weight: 600;
            line-height: 24px;
        }

        p:last-of-type {
            color: $neutralLightText;
            font-size: 14px;
            font-weight: 600;
            line-height: 150%;
        }
    }

    &__allocation_percentage {
        display: flex;
        gap: 8px;
        align-items: center;

        div {
            width: 12px;
            height: 12px;
            border-radius: 50%;
        }

        span {
            color: $darkBlueText;
            font-size: 16px;
            font-weight: 600;
            line-height: 125%;
        }
    }

    &__financials {
        display: flex;
        gap: 20px;

        div {
            width: 120px;
        }

        span {
            display: block;

            &:first-child {
                color: $neutralText;
                font-size: 14px;
                font-weight: 500;
                line-height: 15px;
                margin-bottom: 8px;
            }

            &:last-child {
                color: $darkBlueText;
                font-size: 16px;
                font-weight: 600;
                line-height: 125%;
            }

            &.positive {
                color: $greenText;
            }

            &.negative {
                color: $redText;
            }
        }
    }

    &__financials--dark {
        span {
            &:last-child {
                color: $whiteText;
            }

            &.positive {
                color: $greenText;
            }

            &.negative {
                color: $redText;
            }
        }
    }

    &__holdings_header {
        display: flex;
        gap: 60px;

        span {
            width: 80px;
            text-align: center;
            color: $neutralLightText;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
        }

        span:first-of-type {
            margin-right: auto;
        }
    }

    &__holding {
        display: flex;
        justify-content: space-between;
        padding: 12px 0;
        border-bottom: 1px solid $lightBlueBorder;
        align-items: center;
    }

    &__holding--dark {
        border-bottom: 1px solid lighten($darkBorder, 5%);

        .portfolio__holding_name p {
            color: $whiteText;
        }

        .portfolio__holding_changes span {
            color: $whiteText;
        }
    }

    &__holding_name {
        display: flex;
        gap: 18px;

        img {
            width: 50px;
            height: 50px;
            border-radius: 50%;
            object-fit: cover;
            padding: 8px;
        }

        p {
            color: $darkBlueText;
            font-size: 18px;
            font-weight: 600;
            line-height: 125%;
        }

        p:last-of-type {
            margin-top: 4px;
            font-size: 14px;
            color: $neutralLightText;
        }
    }

    &__holding_changes {
        display: flex;
        gap: 60px;

        span {
            width: 80px;
            text-align: center;
            color: $neutralLightText;
            font-size: 14px;
            font-weight: 600;
            line-height: 24px;
            white-space: nowrap;
            color: $darkBlueText;

            &.positive {
                color: $greenText;
            }

            &.negative {
                color: $redText;
            }
        }
    }
}

.portfolio_chart {
    background-color: $lightBlueBG;
    border-radius: 16px;

    &--dark {
        background-color: lighten($darkBG, 2%);
    }
}

.allocation {
    max-height: 500px;
    overflow: auto;
}

.wallet_holding {
    max-height: 500px;
    overflow: auto;
}
