@use "../../styles/colors.scss" as *;

.community {
    &__col_1,
    &__col_3 {
        width: 25%;
        top: 120px;
        position: sticky;
    }

    &__col_2 {
        width: 50%;
        margin-bottom: 60px;
    }

    &__col_1,
    &__col_2,
    &__col_3 {
        display: flex;
        flex-direction: column;
        gap: 27px;
    }

    &__trending {
        display: flex;
        flex-direction: column;
        gap: 14px;
    }

    &__trending_item {
        display: flex;
        gap: 18px;

        span {
            color: $neutralLightText;
            font-size: 16px;
            font-weight: 550;
        }

        span:last-of-type {
            color: $darkBlueText;
        }

        &--dark {
            span:last-of-type {
                color: $whiteText;
            }
        }
    }

    &__movers {
        display: flex;
        gap: 22px;
        flex-direction: column;
        width: 100%;
    }

    &__subscription {
        display: flex;
        gap: 18px;
        align-items: center;
        cursor: pointer;

        img {
            width: 42px;
            height: 42px;
            border-radius: 50%;
            object-fit: cover;
            background-color: $darkBlueBG;
        }

        p {
            color: $darkBlueText;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
        }

        &--dark {
            img {
                background-color: $darkestBG;
            }

            p {
                color: $whiteText;
            }
        }
    }

    &__forum_header {
        display: flex;
        gap: 16px;

        button {
            color: $neutralLightText;
            font-size: 16px;
            font-weight: 550;
            background-color: transparent;
            border: none;
            padding-bottom: 4px;

            &.active {
                color: $darkBlueText;
                border-bottom: 1.5px solid $darkBlueBorder;
            }
        }

        &--dark button.active {
            color: $whiteText;
            border-bottom: 1.5px solid $whiteBorder;
        }
    }

    &__forum_container {
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__no_subscriptions {
        color: $neutralText;
    }
}

@media (max-width: 1200px) {
    .community {
        flex-direction: column;
    }

    .community__col_1 {
        width: 100%;
        position: static;
    }

    .community__col_3 {
        order: -1;
        width: 100%;
        position: static;
    }

    .community__col_2 {
        width: 100%;
    }
}
