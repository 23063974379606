@use "../../styles/colors.scss" as *;

.comment {
    display: flex;
    gap: 16px;
    flex: 1;
    padding: 16px;

    &__profile_pic {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        object-fit: cover;
        background-color: $darkBlueBG;
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: 14px;
        flex: 1;
    }

    &__header {
        width: 100%;
        display: flex;
    }

    &__name_wrapper {
        display: flex;
        gap: 21px;
        align-items: center;
    }

    &__name {
        color: $darkBlueText;
        font-size: 16px;
        font-weight: 600;
        line-height: 125%;
    }

    &__tag {
        color: $neutralLightText;
        font-size: 12px;
        word-spacing: 5px;
        line-height: 24px;
    }

    &__button {
        display: flex;
        padding: 4px 16px;
        align-items: center;
        gap: 8px;
        border-radius: 36px;
        border: 1px solid $darkBlueBorder !important;
        background-color: transparent;
        border: none;
        color: $darkBlueText;
        font-size: 12px;
        font-weight: 550;
        line-height: 24px;
        margin-left: auto;
        cursor: pointer;

        &.followed {
            background: transparent;
            border: 1px solid $darkBlueBorder;
            color: $darkBlueText;

            &::before {
                display: none;
            }
        }
    }

    &__par {
        color: $neutralText;
        font-size: 14px;
        font-weight: 450;
        line-height: 24px;
    }

    &__img {
        width: 100%;
        height: 180px;
        object-fit: cover;
        border-radius: 12px;
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    &__footer_item {
        display: flex;
        gap: 4px;
        color: $neutralText;
        font-size: 14px;
        font-weight: 450;
        line-height: 22.5px;
        opacity: 0.75;
        cursor: pointer;

        svg {
            width: 20px;
            height: 20px;
        }

        &:hover {
            opacity: 1;
        }
    }

    &--dark {
        .comment__name {
            color: $whiteText;
        }

        .comment__button {
            color: $whiteText;
            border: 1px solid $whiteBorder !important;
        }

        .comment__tag {
            color: $neutralText;
        }

        .comment__par {
            color: $neutralLightText;
        }

        .comment__profile_pic {
            background: $darkestBG;
        }
    }
}
