@use "../../styles/colors.scss" as *;

.auth {
    background-image: url("../../assets/background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    min-height: 100vh;
    display: flex;
    flex-direction: column;

    .content_wrapper {
        width: 100%;
    }

    &__wrapper {
        margin: 124px auto 64px;
        background: $whiteBG;
        max-width: 560px;
        padding: 32px;
        border-radius: 16px;
        box-shadow:
            0px 12px 27px 0px rgba(0, 0, 0, 0.05),
            0px 50px 50px 0px rgba(0, 0, 0, 0.04),
            0px 112px 67px 0px rgba(0, 0, 0, 0.03),
            0px 200px 80px 0px rgba(0, 0, 0, 0.01),
            0px 312px 87px 0px rgba(0, 0, 0, 0);
        display: flex;
        flex-direction: column;
        gap: 48px;

        .title {
            text-align: center;
            font-size: 30px;
            font-weight: 600;
        }
        .resend_code_group {
            width: 100%;
            display: flex;
            justify-content: space-between;

            .resend {
                text-decoration: underline;
                cursor: pointer;
            }

            .resend:hover {
                color: blue;
            }
        }
    }

    &__header {
        display: flex;
        justify-content: center;
        gap: 42px;

        a {
            color: $neutralLightText;
            font-size: 24px;
            font-weight: 600;
            line-height: 125%;
            letter-spacing: 0.5px;
            text-decoration: none;
            display: flex;
            align-items: center;
            flex-direction: column;
            gap: 6px;
            transition: 0.3s color ease;

            &::after {
                content: "";
                display: block;
                width: 0;
                height: 2px;
                background: $darkBlueBG;
                transition: 0.3s width ease;
            }

            &.active {
                color: $darkBlueText;

                &::after {
                    width: 24px;
                }
            }
        }
    }
    
    &__separator {
        display: flex;
        gap: 12px;
        align-items: center;

        hr {
            flex: 1;
            box-shadow: none;
            border: 1px solid $lightBlueBorder;
        }

        span {
            color: $darkBlueText;
            font-size: 14px;
            font-weight: 600;
            line-height: 125%;
            letter-spacing: 0.5px;
        }
    }
}
