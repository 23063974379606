.password {
    display: flex;
    align-items: center;
    position: relative;

    button {
        position: absolute;
        right: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: none;
        cursor: pointer;
        top: 45px;
    }

    img {
        width: 22px;
    }

    .input_container input {
        padding-right: 50px;
    }
}
