@use "../../styles/colors.scss" as *;

.horizontal_asset {
    display: flex;
    gap: 20px;
    justify-content: space-between;
    cursor: pointer;

    &__name {
        display: flex;
        gap: 16px;
        align-items: center;
        flex: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;

        div {
            overflow: hidden;
        }

        p {
            color: $darkBlueText;
            width: 100%;
            font-size: 16px;
            font-weight: 600;
            line-height: 125%;
            letter-spacing: 0.5px;
            margin-bottom: 4px;
            text-overflow: ellipsis;
            overflow: hidden;

            &:last-of-type {
                font-size: 12px;
                color: $neutralLightText;
            }
        }
    }

    &__img {
        border-radius: 50%;
        background: $lightBlueBG;
        display: flex;
        justify-content: center;
        align-items: center;
        flex: none;

        img {
            width: 36px;
            height: 36px;
            object-fit: cover;
        }
    }

    &__price {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        p {
            color: $darkBlueText;
            font-size: 14px;
            font-weight: 600;
            line-height: 125%;
            letter-spacing: 0.5px;
        }

        span {
            font-size: 12px;
            display: flex;
            align-items: center;
            gap: 4px;
            font-weight: 500;

            &::before {
                content: "";
                width: 14px;
                height: 14px;
                background-size: cover;
            }

            &.positive {
                color: $greenText;

                &::before {
                    background-image: url("../../assets/arrow-top-right.svg");
                }
            }

            &.negative {
                color: $redText;

                &::before {
                    background-image: url("../../assets/arrow-bottom-right.svg");
                }
            }
        }
    }

    &--dark {
        .horizontal_asset__name p {
            color: $whiteText;
        }

        .horizontal_asset__price p {
            color: $whiteText;
        }
    }
}
