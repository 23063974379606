@use "../../styles/colors.scss" as *;

path.css-85dcn-MuiAreaElement-root {
    fill: $chartColor;
    opacity: 0.1;
}

.css-1k2u9zb-MuiChartsAxis-root .MuiChartsAxis-line {
    display: none;
}

.MuiChartsAxis-tick {
    display: none;
}

.css-1k2u9zb-MuiChartsAxis-root .MuiChartsAxis-tickLabel {
    tspan {
        font-family: "Poppins", Arial, sans-serif;
        font-size: 12px;
        fill: $neutralLightText;
        font-weight: 500;
    }
}

.MuiChartsAxis-directionY {
    .MuiChartsAxis-tickContainer:first-of-type {
        display: none;
    }

    .MuiChartsAxis-tickLabel {
        tspan {
            font-weight: 600;
        }
    }
}

.chart {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    &__name {
        display: flex;
        gap: 18px;
        align-items: center;

        h2 {
            color: $darkBlueText;
            font-size: 32px;
            font-weight: 600;
            line-height: 125%;
            letter-spacing: 0.5px;
        }

        span {
            font-size: 18px;
            display: flex;
            align-items: center;
            gap: 4px;
            font-weight: 550;

            &::before {
                content: "";
                width: 18px;
                height: 18px;
                background-size: cover;
            }

            &.positive {
                color: $greenText;

                &::before {
                    background-image: url("../../assets/arrow-top-right.svg");
                }
            }

            &.negative {
                color: $redText;

                &::before {
                    background-image: url("../../assets/arrow-bottom-right.svg");
                }
            }
        }

        &--dark {
            h2 {
                color: $whiteText;
            }
        }
    }

    &__period {
        display: flex;
        border-radius: 8px;
        background-color: $lightBlueBG;
        padding: 4px;
        align-items: center;

        span {
            width: 50px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            color: $neutralText;
            font-size: 14px;
            font-weight: 600;
            line-height: 15px;
            cursor: pointer;

            &:nth-child(6) {
                width: 1.5px;
                border-radius: 5px;
                height: 10px;
                background-color: $neutralLightBG;
            }

            &.active {
                background-color: $whiteBG;
                border-radius: 8px;
            }
        }

        &--dark {
            background-color: $darkestBG;

            span.active {
                background-color: $darkBG;
            }
        }
    }

    &__img {
        width: 100%;
        height: auto;
    }

    &__portfolios {
        display: flex;
        gap: 12px;
        color: $neutralLightText;
        font-size: 14px;
        font-weight: 550;
        line-height: 150%;

        span {
            padding: 8px 12px;
            border-radius: 6px;
            cursor: pointer;
        }
        .active {
            background: $lightBlueBG;
        }

        &--dark .active {
            background-color: lighten($darkestBG, 2%);
        }
    }
}

.chart__loader {
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media (max-width: 900px) {
    .chart__header {
        gap: 16px;
        flex-wrap: wrap;
    }
}

@media (max-width: 700px) {
    .chart__portfolios {
        flex-wrap: wrap;
    }
}
