$whiteBG: #ffffff;
$whiteText: #ffffff;
$whiteBorder: #ffffff;

$darkBlueBG: #0d0029;
$darkBlueBorder: #0d0029;
$darkBlueText: #0d0029;

$lightBlueBG: #f8f7ff;

$neutralText: #625b6f;

*,
*::after,
*::before {
    box-sizing: border-box;
    font-family: "GeneralSans-Variable", "Arial", "sans-serif";
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
    scroll-padding-bottom: 160px;
}

img {
    width: auto;
    height: auto;
}

.content_wrapper {
    padding: 0 64px;
    margin: 0 auto;
    max-width: 1600px;
}

.nav {
    background-color: $whiteBG;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;

    &__logo {
        width: 212px;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 78px;
    }

    &__links {
        list-style-type: none;
        display: flex;
        gap: 24px;

        a {
            font-size: 16px;
            font-weight: 500;
            color: $darkBlueText;
            text-decoration: none;
            transition: 0.3s all;

            &:focus,
            &:link,
            &:active,
            &:visited {
                color: $darkBlueText;
            }

            &:hover {
                font-weight: 600;
            }
        }
    }

    &__buttons {
        display: flex;
        gap: 12px;

        a {
            font-size: 16px;
            display: flex;
            padding: 8px 12px;
            background: $darkBlueBG;
            color: $whiteText;
            font-weight: 550;
            align-items: center;
            gap: 10px;
            text-decoration: none;
            border-radius: 36px;
            line-height: 150%;

            &:focus,
            &:link,
            &:active,
            &:visited {
                color: $whiteText;
            }
        }

        a:first-child {
            background: $whiteBG;
            color: $darkBlueText;
            border: 1px solid $darkBlueBorder;

            &:focus,
            &:link,
            &:active,
            &:visited {
                color: $darkBlueText;
            }
        }

        img {
            width: 14px;
        }
    }
}

.hero {
    padding-top: 132px;
    background-image: url("../assets/background.jpg");
    background-size: cover;
    background-repeat: no-repeat;
    height: 1024px;
    overflow: hidden;
    position: relative;

    &::after {
        content: "";
        position: absolute;
        height: 100px;
        bottom: 0;
        left: 0;
        width: 100%;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 100%);
    }

    &__text {
        display: flex;
        align-items: center;
        flex-direction: column;
        gap: 34px;
        margin-bottom: 55px;
    }

    header {
        display: flex;
        gap: 6px;
        align-items: center;
        flex-direction: column;
    }

    p {
        color: $neutralText;
        font-weight: 500;
        line-height: 150%;
        letter-spacing: 0.5px;
        text-transform: uppercase;
    }

    h1 {
        color: $darkBlueText;
        font-size: 56px;
        font-weight: 600;
        line-height: 82px;
        letter-spacing: 0.5px;
    }

    &__img {
        width: 100%;
    }
}

.cta_button {
    background-color: $whiteBG;
    display: flex;
    gap: 11px;
    padding: 14px;
    border-radius: 60px;
    align-items: center;
    border: none;
    cursor: pointer;
    transition: 0.3s all;

    &:hover {
        transform: translateY(-8px);
        box-shadow:
            0px 5px 11px 0px rgba(0, 0, 0, 0.05),
            0px 20px 20px 0px rgba(0, 0, 0, 0.04),
            0px 44px 26px 0px rgba(0, 0, 0, 0.03),
            0px 78px 31px 0px rgba(0, 0, 0, 0.01),
            0px 122px 34px 0px rgba(0, 0, 0, 0);
    }

    div {
        width: 42px;
        height: 42px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50px;
        background-color: $darkBlueBG;

        svg {
            width: 19px;
            height: 19px;
        }
    }

    span {
        color: $darkBlueText;
        font-size: 14px;
        font-weight: 600;
        line-height: 24px;
        text-transform: uppercase;
    }

    &--dark {
        background-color: $darkBlueText;

        span {
            color: white;
        }

        div {
            background-color: #130335;
        }
    }
}

#portfolio_tracker,
#deep_insight {
    .section__img {
        background-color: $darkBlueBG;
        border-radius: 35px;
    }
}

#benchmark {
    .section__img {
        background-image: url("../assets/background.jpg");
        background-size: cover;
        border-radius: 35px;
    }
}

.section {
    padding: 200px 0;

    &__wrapper {
        display: flex;
        gap: 64px;
        align-items: center;
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 32px;
        flex: 1;
        width: 100%;
        align-items: flex-start;

        h2 {
            color: $darkBlueText;
            font-size: 54px;
            font-weight: 600;
            line-height: 125%;
            letter-spacing: 0.5px;
        }

        p {
            color: $neutralText;
            font-weight: 450;
            line-height: 24px;

            &:first-of-type {
                margin-bottom: 12px;
            }
        }
    }

    &__img {
        flex: 1;
        overflow: hidden;

        img {
            width: 100%;
        }
    }

    &__header {
        text-align: center;
        color: $darkBlueText;
        font-size: 42px;
        font-weight: 600;
        line-height: 125%;
        letter-spacing: 0.5px;
        margin-bottom: 42px;
    }

    &--light_blue {
        background: $lightBlueBG;
        padding: 120px 0;
    }

    &__benchmark {
        flex: 1;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }
}

.benchmark_tile {
    border-radius: 24px;
    background: $whiteBG;
    box-shadow: 0px 1px 32px 0px rgba(6, 0, 60, 0.12);
    padding: 40px 30px;
    width: 100%;
    position: relative;
    overflow: hidden;

    h3 {
        color: $darkBlueText;
        font-size: 24px;
        font-weight: 600;
        line-height: 125%;
        letter-spacing: 0.5px;
        margin-bottom: 13px;
    }

    p {
        color: $neutralText;
        font-size: 16px;
        font-weight: 450;
        line-height: 24px;
    }

    &__progress {
        height: 6px;
        width: 100px;
        background: $darkBlueBG;
        position: absolute;
        bottom: 0;
        left: 0;
    }
}

.community_grid {
    display: flex;
    gap: 30px;
    max-width: 1200px;
    margin: 0 auto;

    &__item {
        padding-top: 32px;
        border: 3px solid $whiteBorder;
        border-radius: 24px;
        display: flex;
        flex-direction: column;
        align-items: center;
        flex: 1;
        overflow: hidden;

        &:first-of-type {
            flex: 1.25;
        }
    }

    &__header {
        display: flex;
        padding: 0 32px;
        gap: 16px;
        align-items: flex-end;
        margin-bottom: 32px;
        width: 100%;

        h3 {
            color: $darkBlueText;
            font-size: 42px;
            font-weight: 600;
            line-height: 100%;
            letter-spacing: 0.5px;
        }

        p {
            color: $neutralText;
            font-size: 16px;
            font-weight: 450;
            line-height: 24px;
        }

        .community_grid__h3 {
            color: $darkBlueText;
            font-size: 24px;
            font-weight: 600;
            line-height: 125%;
            letter-spacing: 0.5px;
        }
    }

    img {
        width: 110%;
    }

    img.community_grid__graph {
        width: 90%;
    }
}

.portfolios {
    background-color: white;
    margin: 0 auto;
    padding: 100px 120px;

    &__header {
        color: $darkBlueText;
        text-align: center;
        font-size: 42px;
        font-weight: 600;
        line-height: 125%;
        letter-spacing: 0.5px;
        margin-bottom: 80px;
    }

    &__grid {
        display: grid;
        gap: 30px;
        grid-template-columns: 1fr 1fr 1fr;
    }

    &__item {
        border-radius: 16px;
        padding: 24px;
        display: flex;
        align-items: center;
        flex-direction: column;
        background: $lightBlueBG;
        gap: 16px;

        img {
            width: 64px;
        }

        p {
            color: $darkBlueText;
            font-size: 20px;
            font-weight: 600;
            line-height: 125%;
            letter-spacing: 0.5px;
        }
    }
}

.cta_banner {
    background: $darkBlueBG;
    padding: 64px;
    border-radius: 24px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    h2 {
        color: $whiteText;
        font-size: 42px;
        font-weight: 600;
        line-height: 125%;
        letter-spacing: 0.5px;
    }
}

.faq {
    display: flex;
    flex-direction: column;
    gap: 32px;
    max-width: 1000px;
    margin: 0 auto;
}

.faq_item {
    border-bottom: 1px solid #f0f0f0;

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-bottom: 33px;
    }

    &__plus {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        width: 24px;
        height: 24px;
        cursor: pointer;

        div {
            width: 24px;
            height: 3px;
            border-radius: 3px;
            background: $darkBlueBG;
            position: absolute;

            &:first-of-type {
                transition: 0.3s all;
                transform: rotate(90deg);
            }
        }

        &.open div:first-of-type {
            transform: rotate(0deg);
        }
    }

    p {
        color: $darkBlueText;
        font-size: 24px;
        font-weight: 600;
        line-height: 125%;
        letter-spacing: 0.5px;
    }

    &__body {
        color: $neutralText;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        padding-bottom: 24px;
    }
}

.footer {
    background: $darkBlueBG;

    &__wrapper {
        padding: 64px 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__logo {
        svg {
            margin-bottom: 24px;
        }

        p {
            color: $whiteText;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            opacity: 0.5;
        }
    }

    &__body {
        display: flex;
        justify-content: flex-end;
        gap: 48px;

        h3 {
            color: $whiteText;
            font-weight: 450;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-transform: uppercase;
        }

        a {
            color: $whiteText;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
            text-decoration: none;
            transition: 0.3s all;

            &:focus,
            &:link,
            &:active,
            &:visited {
                color: $whiteText;
            }

            &:hover {
                opacity: 0.5;
            }
        }

        & > div {
            display: flex;
            flex-direction: column;
            gap: 16px;
        }
    }

    &__contacts {
        display: flex;
        flex-direction: row;
        gap: 10px;
    }
}

.menu {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    cursor: pointer;

    &-burger {
        position: absolute;
        width: 2rem;
        height: 0.25rem;
        border-radius: 2rem;
        background-color: $darkBlueBG;
    }

    &-burger::after,
    &-burger::before {
        content: "";
        position: absolute;
        width: 2rem;
        height: 0.25rem;
        border-radius: 2rem;
        background-color: $darkBlueBG;
        transition: all 0.5s;
    }

    &-burger::before {
        transform: translateY(-0.5rem);
    }

    &-burger::after {
        transform: translateY(0.5rem);
    }

    &.open &-burger {
        background-color: transparent;
    }

    &.open &-burger::after {
        background-color: $darkBlueBG;
        transform: rotate(45deg);
    }

    &.open &-burger::before {
        background-color: $darkBlueBG;
        transform: rotate(-45deg);
    }
}

.nav_vertical {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    background-color: $lightBlueBG;
    padding: 200px 24px 24px;
    display: flex;
    flex-direction: column;

    &__links {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 24px;

        a {
            font-size: 18px;
            font-weight: 500;
            color: $darkBlueText;
            text-decoration: none;

            &:focus,
            &:link,
            &:active,
            &:visited {
                color: $darkBlueText;
            }
        }
    }

    .nav__buttons {
        margin-top: auto;
    }
}

@media (max-width: 1024px) {
    .content_wrapper {
        padding: 0 32px;
    }

    .hero {
        h1 {
            font-size: 42px;
            line-height: 120%;
            text-align: center;
        }

        p {
            text-align: center;
        }
    }

    .section {
        padding: 80px 0;

        &__wrapper {
            flex-direction: column-reverse;

            &:nth-child(even) {
                flex-direction: column;
            }
        }

        &__img {
            img {
                max-width: 500px;
            }
        }

        &__text h2 {
            font-size: 32px;
        }

        &__header {
            font-size: 32px;
        }
    }

    .community_grid {
        flex-direction: column;

        img {
            max-width: 500px;
        }

        &__item {
            padding-bottom: 24px;
        }
    }

    .portfolios {
        padding: 32px;

        &__grid {
            grid-template-columns: 1fr 1fr;
            gap: 16px;
        }

        &__header {
            font-size: 32px;
            margin-bottom: 40px;
        }
    }

    .hero {
        height: auto;
    }

    .cta_banner {
        flex-direction: column;
        gap: 32px;
        padding: 32px;

        h2 {
            text-align: center;
            font-size: 32px;
            line-height: 150%;
        }
    }

    .faq_item__header {
        gap: 16px;
    }

    .faq_item p {
        font-size: 18px;
        flex: 1;
    }

    .footer__wrapper {
        flex-direction: column-reverse;
        gap: 32px;
    }

    .footer__body {
        flex-direction: column;
    }
}

@media (max-width: 600px) {
    .portfolios__grid {
        grid-template-columns: 1fr;
    }
}
