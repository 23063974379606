@use "../../../styles/colors.scss" as *;

.search_asset {
    display: flex;
    align-items: center;
    position: relative;

    img {
        width: 20px;
        height: 20px;
        position: absolute;
        left: 16px;

        &:last-child {
            left: auto;
            right: 16px;
        }
    }

    input {
        padding-left: 48px;
        padding-right: 48px;
    }

    &__container {
        position: absolute;
        top: 85px;
        background-color: $darkestBG;
        z-index: 10;
        width: 100%;
        border-radius: 16px;
        height: 200px;
        overflow-y: auto;
    }

    &__item {
        margin-bottom: 1rem;
        padding: 8px 16px;
        cursor: pointer;

        h3 {
            font-size: 16px;
        }

        p {
            font-size: 14px;
            color: $neutralText;
        }

        &:hover {
            background-color: lighten($darkestBG, 5%);
        }
    }
}
