@use "../../styles/colors.scss" as *;

.auth {
    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 48px;
        align-items: center;
        justify-content: center;
        min-height: 250px;
    }

    &__inputs {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 18px;
    }

    &__group {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 10px;

        label {
            color: $darkBlueText;
            font-size: 14px;
            font-weight: 600;
            line-height: 150%;
        }
    }

    &__input_container {
        display: flex;
        justify-content: space-between;

        a {
            color: $neutralLightText;
            font-size: 14px;
            font-weight: 600;
            line-height: 150%;
            text-decoration: none;

            &:visited,
            &:active,
            &:link,
            &:focus {
                color: $neutralLightText;
            }
        }
    }
}
