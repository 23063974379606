@use "../../styles/colors.scss" as *;

.auth {
    &__alt {
        display: flex;
        flex-direction: column;
        gap: 16px;
        width: 100%;
    }

    &__alt_item {
        display: flex;
        padding: 13px 0px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        width: 100%;
        border-radius: 8px;
        background: $lightBlueBG;

        img {
            width: 24px;
        }

        span {
            color: $darkBlueText;
            font-size: 16px;
            font-weight: 500;
            line-height: 21px;
        }
    }
}
