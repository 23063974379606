@use "../../styles/colors.scss" as *;

.article {
    display: flex;
    gap: 20px;
    align-items: center;
    text-decoration: none;

    img {
        width: 150px;
        height: 100px;
        object-fit: cover;
    }

    &__text {
        display: flex;
        flex-direction: column;
        gap: 6px;
    }

    p {
        font-size: 12px;
        color: $neutralLightText;
    }

    h3 {
        font-size: 18px;
        color: $darkBlueText;
        font-weight: 600;
    }

    &--dark {
        h3 {
            color: $whiteText;
        }

        p {
            color: $neutralText;
        }
    }
}
