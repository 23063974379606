@use "../../../styles/colors.scss" as *;

.add_wallet {
    position: fixed;
    z-index: 10;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    align-items: center;
    justify-content: center;

    &__backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(black, 0.5);
    }

    &__header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 24px;
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: 16px;
    }

    &__group {
        width: 100%;
        position: relative;

        label {
            color: $darkBlueText;
            font-size: 14px;
            font-weight: 600;
            line-height: 150%;
            margin-bottom: 8px;
        }

        input {
            padding: 12px 16px;
            width: 100%;
            display: block;
            color: $darkBlueText;
            border-radius: 30px;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            border: none;
            background: $whiteBG;

            &::placeholder {
                color: $neutralLightText;
            }

            &:focus {
                outline: 1px solid $darkBlueBorder;
            }

            &.error {
                outline: 1px solid $redText;
            }
        }
    }


    &__button {
        display: flex;
        padding: 8px 12px;
        text-align: center;
        justify-content: center;
        max-width: 200px;
        margin: 0 auto;
        width: 100%;
        align-items: center;
        border-radius: 36px;
        background: $whiteBG;
        color: $darkText;
        border: none;
        cursor: pointer;
        font-size: 16px;
        font-weight: 550;
        line-height: 24px;

        &:disabled {
            cursor: auto;
            opacity: 0.5;
        }
    }

    &__modal {
        width: 100%;
        max-width: 600px;
        padding: 32px;
        z-index: 30;
        height: 100%;
        max-height: 350px;
        position: relative;
        background-color: $lightBlueBG;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow: auto;

        h3 {
            font-size: 24px;
            color: $darkBlueText;
        }

        .new_post__body {
            background-color: transparent;

            button {
                margin-left: auto;
            }
        }
    }

    &__close {
        background-color: transparent;
        border: none;
        margin-left: auto;
        cursor: pointer;

        img {
            width: 32px;
            height: 32px;
        }
    }

    &--dark {
        .add_transaction__modal {
            background-color: $darkBG;

            h3 {
                color: $whiteText;
            }
        }

        .add_transaction__group {
            label {
                color: $whiteText;
            }

            .select__header,
            .select__body div {
                background-color: $darkestBG;
            }

            input {
                color: $whiteText;
                background: $darkestBG;

                &::placeholder {
                    color: $neutralLightText;
                }

                &:focus {
                    outline: 1px solid $whiteBorder;
                }

                &.error {
                    outline: 1px solid $redText;
                }
            }
        }
    }
}

.add_transaction_button {
    padding: 4px 12px;
    display: flex;
    align-items: center;
    background-color: transparent;
    gap: 8px;
    border-radius: 36px;
    font-size: 16px;
    font-weight: 550;
    line-height: 24px;
    border: 1px solid;
    border-color: $darkBlueBorder;
    color: $darkText;
    &--dark {
        border-color: $whiteBorder;
        color: $whiteText;
    }
}

