@use "../../../styles/colors.scss" as *;

.nav__links {
    display: flex;
    gap: 28px;
    color: $neutralText;
    font-size: 16px;
    font-weight: 600;
    line-height: 125%;

    &--dark {
        color: $neutralLightText;
    }
}
