@use "../../styles/colors.scss" as *;

.post {
    display: flex;
    gap: 16px;
    flex: 1;

    &__profile_pic {
        width: 42px;
        height: 42px;
        border-radius: 50%;
        object-fit: cover;
        background-color: $darkBlueBG;
    }

    &__body {
        display: flex;
        flex-direction: column;
        gap: 14px;
        flex: 1;
    }

    &__header {
        width: 100%;
        display: flex;
    }

    &__name_wrapper {
        display: flex;
        gap: 21px;
        align-items: center;
    }

    a {
        text-decoration: none;
    }

    &__name {
        color: $darkBlueText;
        font-size: 16px;
        font-weight: 600;
        line-height: 125%;
    }

    &__tag {
        color: $neutralLightText;
        font-size: 12px;
        word-spacing: 5px;
        line-height: 24px;
    }

    &__button {
        display: flex;
        padding: 4px 12px;
        align-items: center;
        gap: 8px;
        border-radius: 36px;
        background: $darkBlueBG;
        border: none;
        color: $whiteText;
        font-size: 14px;
        font-weight: 550;
        line-height: 24px;
        margin-left: auto;

        &.followed {
            background: transparent;
            border: 1px solid $darkBlueBorder;
            color: $darkBlueText;

            &::before {
                display: none;
            }
        }
    }

    &__par {
        color: $neutralText;
        font-size: 14px;
        font-weight: 450;
        line-height: 24px;
    }

    &__img {
        width: 100%;
        height: 180px;
        object-fit: cover;
        border-radius: 12px;
    }

    &__footer {
        display: flex;
        align-items: center;
        gap: 24px;
    }

    &__footer_item {
        display: flex;
        gap: 4px;
        color: $neutralText;
        font-size: 14px;
        font-weight: 450;
        line-height: 22.5px;
        opacity: 0.75;
        cursor: pointer;

        svg {
            width: 20px;
            height: 20px;
        }

        &:last-of-type {
            margin-left: auto;
        }

        &:hover {
            opacity: 1;
        }
    }

    &--dark {
        .post__name {
            color: $whiteText;
        }

        .post__button {
            background: $darkestBG;
        }

        .post__button.followed {
            background-color: transparent;
            color: $whiteText;
        }

        .post__tag {
            color: $neutralText;
        }

        .post__par {
            color: $neutralLightText;
        }

        .post__profile_pic {
            background: $darkestBG;
        }
    }
}

@media (max-width: 700px) {
    .post {
        flex-direction: column;
    }
}
