@use "../../styles/colors.scss" as *;

.watchlist {
    &__col_1 {
        flex: 1;
        display: flex;
        flex-direction: column;
        gap: 24px;
    }

    &__col_2 {
        flex: 0.4;
        position: sticky;
        top: 120px;
    }

    &__links {
        display: flex;
        gap: 24px;
        padding: 8px 0;

        a {
            color: $neutralText;
            text-decoration: none;
            font-weight: 500;
        }

        .active {
            color: $darkBlueText;
            padding-bottom: 4px;
            border-bottom: 2px solid $darkBlueBorder;
        }

        &--dark .active {
            color: $whiteText;
            padding-bottom: 4px;
            border-bottom: 2px solid $whiteBorder;
        }
    }

    &__financials {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 32px 96px;

        span {
            display: block;

            &:first-child {
                color: $neutralText;
                font-size: 14px;
                font-weight: 500;
                line-height: 15px;
                margin-bottom: 4px;
            }

            &:last-child {
                color: $darkBlueText;
                font-size: 16px;
                font-weight: 600;
                line-height: 125%;
            }
        }

        &--dark span:last-child {
            color: $whiteText;
        }
    }

    &__about {
        color: $neutralText;
        font-size: 14px;
        line-height: 160%;
    }

    &__container {
        display: flex;
        flex-direction: column;
        gap: 22px;
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__lists {
        display: flex;
        gap: 8px;
        align-items: center;
        background: transparent;
        border: none;

        span {
            color: $darkBlueText;
            font-size: 24px;
            font-weight: 550;
            line-height: 125%;
            letter-spacing: 0.5px;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &__button {
        display: flex;
        padding: 6px 12px;
        gap: 8px;
        align-items: center;
        border-radius: 36px;
        background: $darkBlueBG;
        border: none;
        cursor: pointer;

        span {
            color: $whiteText;
            font-size: 16px;
            font-weight: 550;
            line-height: 24px;
        }

        svg {
            width: 20px;
            height: 20px;
        }
    }

    &__col_2--dark {
        .watchlist__lists span {
            color: $whiteText;
        }

        .watchlist__button {
            background-color: $darkestBG;
        }
    }
}

.watchlist_list {
    max-height: 500px;
    overflow: auto;
}

@media (max-width: 1200px) {
    .watchlist {
        flex-direction: column;
    }

    .watchlist__financials {
        gap: 30px;
    }

    .watchlist__col_2 {
        width: 100%;
        order: -1;
        position: static;
    }
}

@media (max-width: 700px) {
    .watchlist__links {
        flex-wrap: wrap;
    }

    .watchlist__financials {
        grid-template-columns: 1fr 1fr;
    }
}
