@use "../../../styles/colors.scss" as *;

.input_container {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 10px;

    a {
        color: $neutralLightText;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
        text-decoration: none;
    }

    p {
        color: $redText;
        font-size: 14px;
        font-weight: 600;
        text-align: end;
    }

    label {
        color: $darkBlueText;
        font-size: 14px;
        font-weight: 600;
        line-height: 150%;
    }

    input {
        padding: 12px 16px;
        display: block;
        color: $darkBlueText;
        border-radius: 30px;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;
        border: none;
        background: $lightBlueBG;

        &::placeholder {
            color: $neutralLightText;
        }

        &:focus {
            outline: 1px solid $darkBlueBorder;
        }

        &.error {
            outline: 1px solid $redText;
        }
    }

    &--dark {
        label {
            color: $whiteText;
        }

        input {
            background-color: $darkestBG;
            color: $whiteText;

            &:focus {
                outline-color: $whiteBorder;
            }
        }
    }
}
