@use "../../../styles/colors.scss" as *;

.search_results {
    position: absolute;
    top: 96px;
    right: 0;
    background-color: $lightBlueBG;
    border-radius: 12px;
    padding: 24px;
    width: 420px;
    max-height: 420px;
    overflow: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &__body {
        flex: 1;
    }

    &__header {
        display: flex;
        justify-content: flex-end;
        gap: 16px;
        margin-bottom: 16px;

        button {
            background: none;
            border: none;
            cursor: pointer;
            color: $neutralLightText;
            transition: all 0.3s ease;

            &:hover {
                color: $neutralText;
            }
        }

        button.active {
            color: $darkBlueText;
            font-weight: 500;
        }
    }

    .search_results__item {
        background-color: $whiteBG;
        padding: 12px;
        border-bottom: 1px solid $lightBlueBorder;
        cursor: pointer;

        p {
            font-size: 12px;
            color: $neutralLightText;
        }

        img {
            width: 16px;
            height: 16px;
            position: relative;
            opacity: 0.2;
            transition: all 0.3s ease;
        }

        &:hover {
            img {
                opacity: 1;
                transform: translate(2px, -2px);
            }
        }
    }

    &__item_header {
        display: flex;
        justify-content: space-between;
        margin-bottom: 4px;

        h3 {
            font-size: 16px;
            color: $darkBlueText;
        }
    }

    &--dark {
        background-color: $darkBG;

        .search_results__item {
            background-color: $darkBG;
            border-bottom: 1px solid lighten($darkBG, 5%);
        }

        .search_results__item_header h3 {
            color: $whiteText;
        }

        .search_results__header .active {
            color: $whiteText;
        }
    }
}

.search_results__backdrop {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(black, 0.5);
}
