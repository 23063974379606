@use "../../styles/colors" as *;

.asset {
    &__col_1 {
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex: 1;
    }

    &__col_2 {
        position: sticky;
        top: 120px;
        display: flex;
        flex-direction: column;
        gap: 24px;
        flex: 0.5;
    }

    &__financials {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        gap: 32px 24px;

        span {
            display: block;

            &:first-child {
                color: $neutralText;
                font-size: 12px;
                font-weight: 500;
                line-height: 15px;
                margin-bottom: 4px;
            }

            &:last-child {
                color: $darkBlueText;
                font-size: 14px;
                font-weight: 600;
                line-height: 125%;
            }
        }

        &--dark {
            span:last-child {
                color: $whiteText;
            }
        }
    }
}

@media (max-width: 1200px) {
    .asset_container {
        flex-direction: column;
    }

    .asset__col_2 {
        position: static;
        order: -1;
    }
}

@media (max-width: 460px) {
    .asset__financials {
        grid-template-columns: 1fr 1fr;
    }
}
