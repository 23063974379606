@use "../../../styles/colors.scss" as *;

.nav {
    background-color: $whiteBG;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 10;

    &--transparent {
        background-color: transparent;
    }

    &__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 78px;
    }

    &__links {
        display: flex;
        gap: 24px;
        align-items: center;

        a {
            display: flex;
            align-items: center;
            gap: 6px;
            color: $darkBlueText;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
            text-decoration: none;
        }
    }
}

.nav--dark {
    background-color: $darkBG;

    .nav__links a {
        color: $whiteText;
    }
}

.menu {
    z-index: 100;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 3rem;
    width: 3rem;
    cursor: pointer;

    &-burger {
        position: absolute;
        width: 2rem;
        height: 0.25rem;
        border-radius: 2rem;
        background-color: $darkBlueBG;
    }

    &-burger--dark {
        background-color: $whiteBG;
    }

    &-burger::after,
    &-burger::before {
        content: "";
        position: absolute;
        width: 2rem;
        height: 0.25rem;
        border-radius: 2rem;
        background-color: $darkBlueBG;
        transition: all 0.5s;
    }

    &-burger--dark::after,
    &-burger--dark::before {
        background-color: $whiteBG;
    }

    &-burger::before {
        transform: translateY(-0.5rem);
    }

    &-burger::after {
        transform: translateY(0.5rem);
    }

    &.open &-burger {
        background-color: transparent;
    }

    &.open &-burger::after {
        transform: rotate(45deg);
    }

    &.open &-burger::before {
        transform: rotate(-45deg);
    }
}
