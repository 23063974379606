/* Poppins Thin */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Thin.ttf") format("truetype");
  font-weight: 100;
  font-style: normal;
}

/* Poppins ExtraLight */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-ExtraLight.ttf") format("truetype");
  font-weight: 200;
  font-style: normal;
}

/* Poppins Light */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
}

/* Poppins Regular */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400; /* Normal weight */
  font-style: normal;
}

/* Poppins Medium */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

/* Poppins SemiBold */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: normal;
}

/* Poppins Bold */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
}

/* Poppins Black */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Black.ttf") format("truetype");
  font-weight: 900;
  font-style: normal;
}

/* Poppins Thin Italic */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-ThinItalic.ttf") format("truetype");
  font-weight: 100;
  font-style: italic;
}

/* Poppins ExtraLight Italic */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-ExtraLightItalic.ttf") format("truetype");
  font-weight: 200;
  font-style: italic;
}

/* Poppins Light Italic */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-LightItalic.ttf") format("truetype");
  font-weight: 300;
  font-style: italic;
}

/* Poppins Regular Italic */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Italic.ttf") format("truetype");
  font-weight: 400; /* Normal weight */
  font-style: italic;
}

/* Poppins Medium Italic */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

/* Poppins SemiBold Italic */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-SemiBoldItalic.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
}

/* Poppins Bold Italic */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-BoldItalic.ttf") format("truetype");
  font-weight: 700;
  font-style: italic;
}

/* Poppins Black Italic */
@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-BlackItalic.ttf") format("truetype");
  font-weight: 900;
  font-style: italic;
}
