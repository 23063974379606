@use "../../../styles/colors.scss" as *;

.error_message {
    display: flex;
    width: 100vw;
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    align-items: center;
    justify-content: center;
    z-index: 30;

    &__backdrop {
        width: 100%;
        height: 100%;
        position: absolute;
        background-color: rgba(black, 0.5);
        z-index: 30;
    }

    &__modal {
        max-width: 530px;
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 64px;
        border-radius: 12px;
        align-items: center;
        background-color: $whiteBG;
        z-index: 30;
        padding: 32px;
    }

    &__header {
        color: $darkBlueText;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 8px;

        &::after {
            content: "";
            background-color: $darkBlueText;
            width: 20px;
            height: 2px;
            border-radius: 5px;
        }
    }

    &__par {
        color: $neutralText;
    }

    &__button {
        cursor: pointer;
        width: 160px;
        padding: 8px 12px;
        text-align: center;
        border-radius: 36px;
        background: $darkBlueBG;
        border: none;
        color: $whiteText;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }

    &--dark {
        .error_message__modal {
            background-color: $darkBG;
        }

        .error_message__header {
            color: $whiteText;

            &::after {
                background-color: $whiteBorder;
            }
        }

        .error_message__par {
            color: $neutralLightBG;
        }
    }

    .error_message__button {
        background-color: $darkestBG;
    }
}
