@use "../../../styles/colors.scss" as *;

.nav_vertical {
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    background-color: $lightBlueBG;
    padding: 200px 24px 24px;
    display: flex;
    flex-direction: column;
    max-width: 300px;
    width: 100%;

    &--dark {
        background-color: $darkBG;
    }

    &__links {
        list-style-type: none;
        display: flex;
        flex-direction: column;
        gap: 24px;

        a {
            font-size: 18px;
            font-weight: 500;
            color: $darkBlueText;
            text-decoration: none;
            display: flex;
            align-items: center;
            gap: 12px;

            img {
                width: 20px;
                height: 20px;
            }
        }
    }

    &__profile {
        margin-top: auto;

        img {
            width: 48px;
            height: 48px;
            background: $darkBlueBG;
            border-radius: 50%;
            object-fit: cover;
        }
    }

    &--dark .nav_vertical__links a {
        color: $whiteText;
    }

    &--dark .nav_vertical__profile img {
        background: $darkestBG;
    }
}
