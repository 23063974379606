@use "./styles/colors" as *;

*,
*::after,
*::before {
    box-sizing: border-box;
    font-family: "Poppins", "Arial", "sans-serif";
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
    scroll-padding-top: 150px;
}

::-webkit-scrollbar {
    width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $neutralBG;
    border-radius: 5px;
}

img {
    width: auto;
    height: auto;
}

.content_wrapper {
    padding: 0 64px;
    margin: 0 auto;
    max-width: 1600px;
}

.setup {
    min-height: 100vh;
    background: $lightBlueBG;

    &__wrapper {
        display: flex;
        flex-direction: column;
        margin: 40px auto 0;
        max-width: 510px;
        align-items: center;
        width: 100%;
        gap: 40px;
        padding-bottom: 60px;

        &--wide {
            max-width: 750px;
        }
    }

    &__nav {
        display: flex;
        gap: 26px;
        flex-wrap: wrap;

        button {
            display: flex;
            gap: 12px;
            background: none;
            border: none;
            color: $neutralLightText;
            font-size: 16px;
            font-weight: 500;
            line-height: 24px;
        }

        button.active {
            color: $darkBlueText;
            cursor: pointer;

            span:first-of-type {
                color: $whiteText;
                background: $darkBlueBG;
                border: 1px solid $darkBlueBorder;
            }
        }

        span:first-of-type {
            display: flex;
            width: 24px;
            height: 24px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 50%;
            border: 1px solid $neutralLightBorder;
        }
    }

    &__container {
        padding: 32px;
        display: flex;
        flex-direction: column;
        gap: 48px;
        background: $whiteBG;
        width: 100%;
        border-radius: 16px;
    }

    &__header {
        color: $darkBlueText;
        font-size: 24px;
        font-weight: 600;
        line-height: 125%;
        letter-spacing: 0.5px;
    }

    &__img_container {
        display: flex;
        align-items: center;
        gap: 17px;

        div {
            flex: 1;
        }

        p:first-of-type {
            color: $darkBlueText;
            font-size: 16px;
            font-weight: 600;
            line-height: 24px;
        }

        p:last-of-type {
            color: $neutralLightText;
            font-size: 14px;
            font-weight: 500;
            line-height: 150%;
        }
    }

    &__form {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 48px;
        align-items: center;
    }

    &__inputs {
        display: flex;
        flex-direction: column;
        width: 100%;
        gap: 18px;
    }

    &__button {
        cursor: pointer;
        width: 160px;
        padding: 8px 12px;
        text-align: center;
        border-radius: 36px;
        background: $darkBlueBG;
        border: none;

        color: $whiteText;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
    }

    &__portfolios {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        gap: 24px;
    }
    &__group {
        label {
            font-size: 14px;     
            font-weight: 600;       
        }
        input {
            width: 100%;
            padding: 12px;
            border-radius: 14px;
        }
    }
    &__portfolio {
        padding: 12px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 16px;
        border-radius: 14px;
        background: $lightBlueBG;
        cursor: pointer;

        img {
            width: 50px;
        }

        p {
            color: $darkBlueText;
            font-size: 16px;
            font-weight: 600;
            line-height: 125%;
        }
    }

    &__link {
        color: $neutralLightText;
        font-size: 16px;
        font-weight: 600;
        text-decoration: none;
        display: flex;
        align-items: center;
        gap: 4px;
        cursor: pointer;
        line-height: 24px;
    }
}

.dashboard_section {
    height: 100vh;
    overflow: auto;
    background-color: $lightBlueBG;

    &__wrapper {
        margin-top: 43px;
        display: flex;
        gap: 20px;
        align-items: flex-start;
    }

    &--dark {
        background-color: $darkestBG;
    }
}

.support_button {
    margin-top: auto;
    display: flex;
    gap: 10px;
    color: $neutralLightText;
    font-size: 14px;
    font-weight: 600;
    line-height: 24px;
    align-items: center;
    background-color: transparent;
    border: none;

    img {
        width: 20px;
        height: 20px;
    }
}

@media (max-width: 1200px) {
    .content_wrapper {
        padding: 0 32px;
    }

    .setup__portfolios {
        grid-template-columns: 1fr 1fr;
    }

    .portfolio__holdings_header {
        gap: 12px;
    }

    .portfolio__holding_changes {
        gap: 12px;
    }

    .portfolio__header p {
        font-size: 32px;
    }
}

@media (max-width: 900px) {
    .portfolio__holdings_header {
        span:nth-child(5),
        span:nth-child(6) {
            display: none;
        }
    }

    .portfolio__holding_changes {
        span:nth-child(5),
        span:nth-child(6) {
            display: none;
        }
    }

    .portfolio__wrapper {
        flex-direction: column;
    }
}

@media (max-width: 700px) {
    .setup__portfolios {
        grid-template-columns: 1fr;
    }

    .portfolio__financials {
        flex-wrap: wrap;
    }

    .dashboard_section__wrapper {
        flex-direction: column;
    }

    .settings__col_1,
    .settings__col_2 {
        min-height: 0;
        width: 100%;
    }

    .portfolio__holdings_header {
        span:nth-child(3),
        span:nth-child(4),
        span:nth-child(2) {
            display: none;
        }
    }

    .portfolio__holding_changes {
        span:nth-child(3),
        span:nth-child(4),
        span:nth-child(2) {
            display: none;
        }
    }
}

@media (max-width: 460px) {
    .portfolio__holdings_header {
        span:nth-child(7) {
            display: none;
        }
    }

    .portfolio__holding_changes {
        span:nth-child(7) {
            display: none;
        }
    }
}
