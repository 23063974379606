@use "../../../styles/colors.scss" as *;

.form_button {
  cursor: pointer;
  width: 160px;
  padding: 8px 12px;
  text-align: center;
  border-radius: 36px;
  background: $darkBlueBG;
  border: none;
  color: $whiteText;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;

  &--dark {
    background: $darkBG;
    border: 1px solid $whiteBorder;
  }
}
