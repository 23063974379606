@use "../../styles/colors.scss" as *;

.post_comments {
    position: fixed;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 32px;

    &__backdrop {
        position: absolute;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100vh;
        background-color: rgba(black, 0.5);
    }

    &__modal {
        width: 100%;
        max-width: 800px;
        padding: 32px;
        z-index: 30;
        height: 100%;
        max-height: 700px;
        position: relative;
        background-color: $lightBlueBG;
        border-radius: 16px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        overflow: auto;

        h3 {
            font-size: 16px;
            color: $darkBlueText;
            margin-top: 32px;
        }

        .post {
            background-color: $whiteBG;
            padding: 16px;
            border-radius: 8px;
            flex: none;
            width: 100%;
        }

        .new_post__body {
            background-color: transparent;

            button {
                margin-left: auto;
            }
        }

        & > button {
            background-color: transparent;
            border: none;
            margin-left: auto;
            cursor: pointer;

            img {
                width: 24px;
                height: 24px;
            }
        }
    }

    &--dark {
        .post_comments__modal {
            background-color: $darkBG;

            .post {
                background-color: $darkestBG;
            }

            h3 {
                color: $whiteText;
            }

            .new_post {
                background-color: $darkestBG;

                textarea {
                    background-color: $darkBG;
                }
            }
        }
    }
}
