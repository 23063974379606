@use "../../styles/colors.scss" as *;

.loader {
    width: 40px;
    height: 40px;
    padding: 6px;
    aspect-ratio: 1;
    border-radius: 50%;
    background: $darkBlueBorder;
    --_m: conic-gradient(#0000 10%, #000), linear-gradient(#000 0 0) content-box;
    -webkit-mask: var(--_m);
    mask: var(--_m);
    -webkit-mask-composite: source-out;
    mask-composite: subtract;
    animation: l3 1s infinite linear;

    &--light {
        background: $whiteBorder;
    }

    &__wrapper {
        flex: 1;
        width: 100%;
        padding: 24px;
        display: flex;
        justify-content: center;
    }
}
@keyframes l3 {
    to {
        transform: rotate(1turn);
    }
}
