@use "../../../styles/colors.scss" as *;

.search {
    width: 100%;
    display: flex;
    align-items: center;
    position: relative;

    img {
        position: absolute;
        left: 12px;
        width: 24px;
        height: 24px;
        background: transparent;
        z-index: 11;
    }

    input {
        padding: 12px 12px 12px 48px;
        background-color: $lightBlueBG;
        border-radius: 36px;
        border: none;
        color: $darkBlueText;
        font-size: 18px;
        font-weight: 450;
        line-height: 24px;
        width: 100%;
        position: relative;
        z-index: 10;

        &:focus {
            outline: 1px solid $darkBlueBorder;
        }

        &::placeholder {
            color: $neutralLightText;
        }
    }
}

.search--dark {
    input {
        background-color: $darkestBG;
        color: $whiteText;

        &:focus {
            outline: 1px solid $whiteBorder;
        }
    }

    img {
        position: absolute;
        left: 12px;
        width: 24px;
        height: 24px;
        background: transparent;
    }
}
