// Light Theme
$whiteBG: #ffffff;
$whiteText: #ffffff;
$whiteBorder: #ffffff;

$darkBlueBG: #0d0029;
$darkBlueBorder: #0d0029;
$darkBlueText: #0d0029;

$lightBlueBG: #f8f7ff;
$lightBlueBorder: #f8f7ff;

$neutralText: #625b6f;
$neutralBG: #625b6f;

$neutralLightBG: #ada8b9;
$neutralLightBorder: #ada8b9;
$neutralLightText: #ada8b9;

$greenText: #3ccd65;
$redText: #ea3c45;

$chartColor: #7346d2;

// Dark Theme
$darkBG: #1c1f24;
$darkText: #1c1f24;
$darkBorder: #1c1f24;

$whiteBG: #ffffff;
$whiteBorder: #ffffff;
$whiteText: #ffffff;

$darkestBG: #131518;
$darkestBorder: #131518;

// $neutralText: #A4A5A7;
// $neutralBG: #A4A5A7;

// $neutralLightBG: #ada8b9;
// $neutralLightBorder: #ada8b9;
// $neutralLightText: #ada8b9;

// $greenText: #3ccd65;
// $redText: #ea3c45;

// $chartColor: #7346d2;
