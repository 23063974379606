@use "../../../styles/colors.scss" as *;

.nav__profile {
    display: flex;
    gap: 12px;
    width: 300px;

    img:not(.search img) {
        width: 48px;
        height: 48px;
        background: $darkBlueBG;
        border-radius: 50%;
        object-fit: cover;
    }
}

.nav__profile--dark {
    img:not(.search img) {
        background: $darkestBG;
    }
}
