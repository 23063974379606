@use "../../styles/colors.scss" as *;

.asset_header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;

    &__name {
        display: flex;
        align-items: center;
        gap: 20px;

        p {
            color: $darkBlueText;
            font-size: 24px;
            font-weight: 600;
            line-height: 125%;
            letter-spacing: 0.5px;
        }

        p:last-of-type {
            margin-top: 4px;
            color: $neutralLightText;
            font-size: 16px;
        }
    }

    &__img {
        width: 48px;
        height: 48px;
        background-color: $lightBlueBG;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 48px;
            height: 48px;
            object-fit: cover;
        }
    }

    &__save {
        display: flex;
        gap: 16px;
        align-items: center;

        button {
            padding: 4px 12px;
            display: flex;
            align-items: center;
            background-color: transparent;
            gap: 8px;
            border-radius: 36px;
            border: 1px solid $darkBlueBorder;
            color: $darkBlueText;
            font-size: 16px;
            font-weight: 550;
            line-height: 24px;

            img {
                width: 18px;
                height: 18px;
                cursor: pointer;
            }
        }

        img {
            width: 21px;
            height: 21px;
            cursor: pointer;
        }
    }

    &--dark {
        .asset_header__name p {
            color: $whiteText;
        }

        .asset_header__save button {
            border: 1px solid $whiteBorder;
            color: $whiteText;
        }
    }
}

@media (max-width: 1200px) {
    .asset_header {
        flex-wrap: wrap;
        gap: 24px;
    }
}
