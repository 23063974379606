@use "../../styles/colors.scss" as *;

.new_post {
    padding: 16px;
    display: flex;
    gap: 12px;
    border-radius: 12px;
    background-color: $whiteBG;

    & > img {
        width: 50px;
        height: 50px;
        border-radius: 50%;
        background-color: $darkBlueBG;
    }

    &__body {
        flex: 1;
    }

    &__footer {
        display: flex;
        margin-top: 16px;
        justify-content: space-between;
        align-items: center;

        img {
            border-radius: 0;
            width: 20px;
            height: 20px;
            background-color: transparent;
        }
    }

    &__img_wrapper {
        position: relative;
        margin-top: 16px;
    }

    &__img {
        width: 100%;
        height: 180px;
        border-radius: 8px;
        object-fit: cover;
    }

    &__clear {
        position: absolute;
        top: 4px;
        cursor: pointer;
        right: 4px;
        width: 24px;
        height: 24px;
    }

    textarea {
        padding: 12px;
        border: none;
        background: $lightBlueBG;
        font-size: 14px;
        font-weight: 500;
        border-radius: 8px;
        display: block;
        width: 100%;
        resize: vertical;

        &:focus {
            outline: 1px solid $darkBlueBorder;
        }

        &::placeholder {
            color: $neutralLightText;
        }
    }

    input {
        display: none;
    }

    label {
        cursor: pointer;
        opacity: 0.5;
        transition: 0.3s all ease;

        &:hover {
            opacity: 1;
        }
    }

    button {
        padding: 8px 12px;
        background: $darkBlueBG;
        border: none;
        border-radius: 36px;
        color: $whiteText;
        font-weight: 600;
        font-size: 14px;
        width: 80px;
        cursor: pointer;
    }
}

.new_post--dark {
    background-color: $darkBG;

    & > img {
        background-color: $darkestBG;
    }

    textarea {
        background: $darkestBG;
        color: $whiteText;

        &:focus {
            outline: 1px solid $whiteBorder;
        }
    }

    button {
        background-color: lighten($darkBG, 5%);
        color: $whiteText;
    }
}
